import React from "react";
import { observer, inject } from "mobx-react";
import CalendarHeader from "./subcomponents/calendarHeader";
import ProjectLines from "./subcomponents/projectLines";
import MixingPlantLines from "./subcomponents/mixingPlantLines";
import ResourceLines from "./subcomponents/resourceLines";
import LineHeader from "./subcomponents/lineHeader";
import VerticalLines from "./subcomponents/verticalLines";
import ResourceChooser from "./controls/resourceChooser";
import RequirementChooser from "./controls/requirementChooser";
import ReverseChooser from "./controls/reverseChooser";
import JobChooser from "./controls/jobChooser";
import DayViewPage from "../../containers/dayview";
import JobEditModal from "./controls/jobEditModal";

class Calendar extends React.Component {
	constructor(props) {
		super(props);
		this.firstColumn = React.createRef();
		this.onScroll = this.onScroll.bind(this);
		this.toggleLineHeader = this.toggleLineHeader.bind(this);
		this.saveResChooser = this.saveResChooser.bind(this);
		this.saveReqChooser = this.saveReqChooser.bind(this);
		this.closeResChooser = this.closeResChooser.bind(this);
		this.state = {
			lastClick: [0, ""],
			clickMode: 0,
			selected: [],
			selectedJobs: [],
			resChooserOpen: false,
			lastRequirement: { id: false },
			lastProject: "",
			lastView: props.app.ui.mode,
			onlyDrivers: false
		};
	}

	static getDerivedStateFromProps(props, state) {
		if (props.app.ui.mode !== state.lastView) {
			return {
				lastView: props.app.ui.mode,
				selected: [],
				resChooserOpen: false,
				selectedJobs: []
			};
		}
		if (!state.resChooserOpen && props.app.ui.resourceEditor !== false) {
			//resource editor was opened from detail view
			return {
				selected: props.app.ui.resourceEditor,
				resChooserOpen: true,
				selectedJobs: props.app.ui.resourceEditor,
				onlyDrivers: props.app.ui.selectOnlyDrivers
			};
		}
		return null;
	}

	componentDidMount() {
		this.setScroll(true);
		window.addEventListener(
			"resize",
			function() {
				this.props.app.ui.calScrollNeeded();
			}.bind(this)
		);
	}
	componentDidUpdate(prevProps) {
		this.setScroll();
	}
	closeResChooser() {
		this.props.app.ui.closeResourceEditor();
		this.setState({
			resChooserOpen: false,
			selected: [],
			selectedJobs: [],
			lastRequirement: { id: false }
		});
	}
	saveReqChooser(
		selectedJobs,
		selectedRequirements
	) {
		const operations = [];
		for (let pid in selectedJobs){
			operations.push({jobs: selectedJobs[pid], requests: selectedRequirements[pid]});
		}
		this.props.app.projects.requestDispose(operations);
		this.closeResChooser();
	}

	/*
	saveResChooser(
		selectedJobs,
		selectedResources,
		initiallySelectedResources,
		project,
		onlyDrivers = false
	) {
		let add = selectedResources.filter(
			x => !initiallySelectedResources.includes(x)
		);
		let remove = initiallySelectedResources.filter(
			x => !selectedResources.includes(x)
		);
		const operations = new Map();
		const groupOperations = new Map();
		const rentalOperations = new Map();
		for (let rid of add) {
			if (rid.startsWith("RENT#")){
				rentalOperations.set(rid.replace(/RENT#/,""), "ADD");
			} else if (rid.startsWith("GROUP#")){
				groupOperations.set(rid.replace(/GROUP#/,""), "ADD");
			} else {
				operations.set(rid, "ADD");
			}
		}
		for (let rid of remove) {
			if (rid.startsWith("RENT#")){
				rentalOperations.set(rid.replace(/RENT#/,""), "REMOVE");
			} else if (rid.startsWith("GROUP#")){
				groupOperations.set(rid.replace(/GROUP#/,""), "REMOVE");
			} else {
				operations.set(rid, "REMOVE");
			}
		}  

		this.props.app.resources.dispose(selectedJobs, operations, onlyDrivers);
		this.props.app.resources.groupDispose(selectedJobs, groupOperations);
		project.rentalDispose(selectedJobs, rentalOperations);
		this.closeResChooser();
	}
*/	
	saveResChooser( 
		initiallySelectedResources,
		project,
		onlyDrivers = false,
		selectedIndividualProcess
	) {
 		let app = this.props.app;
		Object.entries(selectedIndividualProcess).forEach(([key, process]) => {
			if (process.jobs.length === 0) return;  

			let add = process.resources.filter(
				x => !initiallySelectedResources[key].resources.includes(x)
			);
			let remove = initiallySelectedResources[key].resources.filter(
				x => !process.resources.includes(x)
			);
			const operations = new Map();
			const groupOperations = new Map();
			const rentalOperations = new Map();
			for (let rid of add) {
				if (rid.startsWith("RENT#")){
					rentalOperations.set(rid.replace(/RENT#/,""), "ADD");
				} else if (rid.startsWith("GROUP#")){
					groupOperations.set(rid.replace(/GROUP#/,""), "ADD");
				} else {
					operations.set(rid, "ADD");
				}
			}
			for (let rid of remove) {
				if (rid.startsWith("RENT#")){
					rentalOperations.set(rid.replace(/RENT#/,""), "REMOVE");
				} else if (rid.startsWith("GROUP#")){
					groupOperations.set(rid.replace(/GROUP#/,""), "REMOVE");
				} else {
					operations.set(rid, "REMOVE");
				}
			}
			app.resources.dispose(process.jobs, operations, onlyDrivers);
			app.resources.groupDispose(process.jobs, groupOperations);
			project.rentalDispose(process.jobs, rentalOperations);
		});
		this.closeResChooser();
	}

	setScroll(force = false) {
		if (!this.props.app.ui.dayView && (this.props.app.ui.calScrollNecessary || force)) {
			this.refs.calWrap.scrollLeft =
				((this.props.app.ui.calStart.getTime() -
					this.props.app.ui.view.start.getTime()) /
					this.props.app.ui.calDuration) *
				this.props.width;
			this.props.app.ui.calScrollDone();
		}
	}
	toggleLineHeader(e, target) {
		this.props.app.ui.toggleHide(e.target.dataset.module);
	}
	onScroll(e) {
		let leftpos =
			(this.props.app.ui.calDuration * this.refs.calWrap.scrollLeft) /
				this.props.width +
			this.props.app.ui.view.start.getTime();
		this.props.app.ui.setCalPosition(new Date(leftpos));
		let rightpos =
			(this.props.app.ui.calDuration *
				(this.refs.calWrap.scrollLeft +
					this.props.width -
					this.firstColumn.current.clientWidth)) /
				this.props.width +
			this.props.app.ui.view.start.getTime();
		if (
			leftpos < this.props.app.ui.paddedView.start ||
			rightpos > this.props.app.ui.paddedView.end
		) {
			this.props.app.ui.setCalStart(leftpos);
		}
	}
	resetSelected() {
		this.setState({
			selected: [],
			clickMode: 0,
			lastClick: [0, ""]
		});
	}
	handleResClick(event, project, process, date, requirement = { id: false }, forceOpen = false) {
		event.persist();
		const clickMode = (process ? 2 : 0) + (date ? 1 : 0);
		const key =
			project +
			"#" +
			(process ? process : "*") +
			"#" +
			(date ? date : "*");
		this.setState(prevState => {
			if (
				forceOpen || (
				requirement.id === prevState.lastRequirement.id &&
				event.timeStamp - prevState.lastClick[0] < 500 &&
				key === prevState.lastClick[1])
			) {
				const selected =
					prevState.clickMode === clickMode ? prevState.selected : [];

				const keyIndex = selected.indexOf(key);
				if (keyIndex === -1) {
					selected.push(key);
				}
				//console.log("begib", selected);

				const res_ok = this.props.app.ui.rights.has("PLAN_RESOURCES");
				const req_ok = this.props.app.ui.modules.has("REQUEST_PLANNING") && this.props.app.ui.rights.has("PLAN_REQUESTS");

				if (requirement.id && this.props.app.ui.requirementMode && res_ok)
					this.props.app.ui.setRequirementMode(false);
				else if (!this.props.app.ui.requirementMode && this.props.app.ui.mode === "CALENDAR" && req_ok && !forceOpen)
					this.props.app.ui.setRequirementMode(true);

				if (this.props.app.ui.requirementMode && !req_ok){
					this.props.app.ui.setRequirementMode(false);
				} else if (!this.props.app.ui.requirementMode && !res_ok){
					this.props.app.ui.setRequirementMode(true);
				}

				return {
					clickMode: 0,
					lastClick: [0, ""],
					resChooserOpen: true,
					lastProject: project,
					selected,
					lastRequirement: requirement,
					selectedJobs: selected,
					onlyDrivers: false
				};
			} else {
				//console.log(key);
				const selected =
					prevState.clickMode === clickMode &&
					prevState.lastProject === project &&
					prevState.lastRequirement.id === requirement.id
						? prevState.selected
						: [];
				const keyIndex = selected.indexOf(key);
				if (keyIndex > -1) {
					//selected.splice(keyIndex);
					selected.splice(keyIndex, 1);
				} else {
					selected.push(key);
				}
				return {
					lastRequirement: requirement,
					lastProject: project,
					selected,
					clickMode,
					lastClick: [event.timeStamp, key],
					onlyDrivers: false
				};
			}
		});
	}
	render() {
		const canSeeMixingPlants =
			!this.props.edit && this.props.app.ui.mode === "RESOURCES";
		const canSeeResources =
			!this.props.edit &&
			(this.props.app.ui.mode === "RESOURCES" ||
				this.props.app.ui.mode === "BOARD");
		const canSeeProjects =
			this.props.edit || this.props.app.ui.mode !== "BOARD";

		const showHeaders =
			this.props.app.ui.mode !== "BOARD" &&
			(canSeeMixingPlants || canSeeResources);

		const showProjects =
			canSeeProjects &&
			(!showHeaders || !this.props.app.ui.hide.includes("projects"));
		const showResources =
			canSeeResources && !this.props.app.ui.hide.includes("resources");
		const showMixingPlants =
			canSeeMixingPlants &&
			!this.props.app.ui.hide.includes("mixingplants");

		const lineHeaderWidth =
			((this.props.app.ui.view.end - this.props.app.ui.view.start) /
				this.props.app.ui.calDuration) *
			this.props.width;

		// eslint-disable-next-line
		const mobxForceObserve = this.props.app.ui.resourceEditor;

		return (
			<React.Fragment>
				<JobChooser />
				<JobEditModal />
				<ReverseChooser
					save={x => x}
					close={x => x}
					data={this.props.app.ui.reverseDispo}
				/>
				<ResourceChooser 
					save={this.saveResChooser}
					close={this.closeResChooser}
					open={!this.props.app.ui.requirementMode && this.state.resChooserOpen}
					selectedJobs={this.state.selectedJobs}
					requirement={this.state.lastRequirement}
					onlyDrivers={this.state.onlyDrivers}
				/>
				<RequirementChooser
					save={this.saveReqChooser}
					close={this.closeResChooser}
					open={this.props.app.ui.requirementMode && this.state.resChooserOpen}
					selectedJobs={this.state.selectedJobs}
				/>
				{this.props.app.ui.dayView ? <DayViewPage calendarCtx={this} appCtx={this.props.appCtx} /> :
				<div
					key="xdiv7"
					className={
						"calendar_wrapper" +
						(!this.props.edit &&
						this.props.app.ui.mode === "RESOURCES"
							? " resmode"
							: "")
					}
					ref="calWrap"
					onScroll={this.onScroll}
				>
					<table className="calendar" key="xdiv6">
						<CalendarHeader
							key="xdiv5"
							xref={this.firstColumn}
							calDuration={this.props.app.ui.calDuration}
							xwidth={this.props.width}
							view={this.props.app.ui.view}
							snap={this.props.app.ui.calSnap}
							edit={this.props.edit ? this.props.edit : false}
						/>
						<tbody>
							{showHeaders && canSeeMixingPlants ? (
								<LineHeader
									module="mixingplants"
									active={showMixingPlants}
									onToggle={this.toggleLineHeader}
									width={lineHeaderWidth}
								/>
							) : null}
							{showMixingPlants ? (
								<MixingPlantLines
									calendar={this}
									width={this.props.width}
								/>
							) : null}
							{showHeaders ? (
								<LineHeader
									module="projects"
									active={showProjects}
									onToggle={this.toggleLineHeader}
									width={lineHeaderWidth}
								/>
							) : null}
							{showProjects ? (
								<ProjectLines
									calendar={this}
									width={this.props.width}
									edit={
										this.props.edit
											? this.props.edit
											: false
									}
								/>
							) : null}
							{showHeaders && canSeeResources ? (
								<LineHeader
									module="resources"
									active={showResources}
									onToggle={this.toggleLineHeader}
									width={lineHeaderWidth}
								/>
							) : null}
							{showResources ? (
								<ResourceLines width={this.props.width} />
							) : null}
							<VerticalLines
								calDuration={this.props.app.ui.calDuration}
								xheight={this.props.height}
								xwidth={this.props.width}
								view={this.props.app.ui.view}
								snap={this.props.app.ui.calSnap}
							/>
						</tbody>
					</table>
				</div>}
			</React.Fragment>
		);
	}
}

export default inject("app")(observer(Calendar));
