import React from "react";
import uuid from "uuid";
import omit from "lodash.omit";
import pick from "lodash.pick";
import clonedeep from "lodash.clonedeep";
import { withTranslation } from "react-i18next";
import LZString from "lz-string";
import moment from "moment";
import ContainerDimensions from "react-container-dimensions";
import { observer, inject } from "mobx-react";
import Calendar from "../components/calendar/calendar";
import { withRouter } from "react-router-dom";
import md5 from "md5";
import ConfirmButton from "../components/calendar/controls/confirmButton";
import CalendarButtons from "../components/calendar/calendarButtons";
import ProjectEditView from "../components/calendar/projecteditview";
import GlobalProjectEdit from "../components/calendar/projecteditview/globalProjectEdit";
import SaveImg from "../images/save.png";
import WahlImg from "../images/wahl.png";

// Import Common Stylesheets

class ProjectEditPage extends React.Component {

	constructor(props) {
		super(props);
		
		this.myRef = React.createRef();

		this.isFullPlanning = true;
		
		this.state = {
			hidden_form_value:'',
			pid: "",
			forceRemerge: 1,
			data: {},
			neverChanged: true,
			deleteMode: false,
			marked: new Set(),
			orders: new Map(),
			disposition: new Map(),
			suppliers: new Set(),
			requests: new Map(),
			global: false,
			copyOrigins: new Set(),
			newProject: false,
			multiMode: false,
			activeIndex: 0,
			activeGlobalIndex: 0,
			history: [],
			forwardHistory: [],
			agfAcceptedMaterials: [], 
			trucksErrorModalOpen: false
		};
	}

	handleTabChange = (e, { activeIndex }) => this.setState({ activeIndex });
	handleGlobalTabChange = (e, { activeGlobalIndex }) =>
		this.setState({ activeGlobalIndex });

	setGlobal(global) {
		this.setState({
			global,
			newProject: false,
		});
	}

	setMultiMode(multiMode) {
		this.setState({
			multiMode,
		});
	}

	setErrorsModalOpen(open){
		this.setState({
			trucksErrorModalOpen: open
		});
	}

	undo() { 
		this.setState(function(state) { 
			state.forwardHistory.unshift(state.history.pop());
			const newState = state.history[state.history.length - 1];
			return {
				...newState,
				forwardHistory: state.forwardHistory,
				history: state.history,
				forceRemerge: new Date(),
			};
		});		
	}

	redo() {
		this.setState(function(state) {
			const newState = state.forwardHistory.shift();
			state.history.push(newState);
			return {
				...newState,
				forwardHistory: state.forwardHistory,
				history: state.history,
				forceRemerge: new Date(),
			};
		});
	}

	saveHistory() {
		this.setState(function(state) {
			const history = state.history.slice(-4);
			history.push(
				clonedeep(
					pick(state, [
						"data",
						"neverChanged",
						"marked",
						"orders",
						"disposition",
						"requests",
						"global",
						"activeIndex",
						"activeGlobalIndex",
					])
				)
			);
			return { history, forwardHistory: [] };
		});
	}

	componentDidMount() {
		this.getProjectData(this.props.match.params.id);
	}

	checkForChanges() {
		return this.state.neverChanged || !this.state.data.editable;
	}

	componentDidUpdate(prevProps) {
		if (prevProps.match.params.id !== this.props.match.params.id)
			this.getProjectData(this.props.match.params.id);
	}

	componentWillUnmount() {
		this.props.appCtx.setEditButtons(false);
	}

	validateBstn(){
		let currentProjects=Array.from(this.props.app.projects.projects.values());
		let filteredProjects=currentProjects.filter( x => 
			x.bstn === this.state.data.bstn
		  );
		 console.log(filteredProjects) 
		if(filteredProjects.length>0){
			alert('Bouwplaatsnummer already exist')
		}

	}
	

	getProjectData(pid, setCalStart = true) {
		if (typeof pid === "undefined") pid = uuid.v4();
		else if (
			!this.props.app.projects.projects.has(pid) ||
			this.props.app.projects.projects.get(pid).deleted
		)
			this.props.history.push("/");

		let projectData;
		let orderData = new Map();
		let dispoData = new Map();
		let requestData = new Map();
		let agfAcceptedMaterials = new Set(); 
		let global = false;
		let newProject = false; 

		if (this.props.app.projects.projects.has(pid)) {
			const pNode = this.props.app.projects.projects.get(pid);
			orderData = pNode.jobMapList("$orders");
			dispoData = pNode.jobMapList("$disposition");
			requestData = pNode.jobMapList("$requests");
			projectData = pNode.oldFormat();
			//console.log(projectData);
			//console.log(pNode.start);

			if (
				pNode.agfData &&
				pNode.agfData.data &&
				pNode.agfData.hash === pNode.agfData.hashAccepted &&
				pNode.agfData.data.sections.length
			) {
				for (let section of pNode.agfData.data.sections) {
					if (!section.layers || section.layers.length === 0) continue;
					for (let layer of section.layers) {
						agfAcceptedMaterials.add(
							[layer.mixtureName, layer.mixingPlant, layer.mixtureCode].join(
								"#####"
							)
						);
					}
				}
			}

			if (pNode.start && setCalStart) {
				const calstart = moment(pNode.start)
					.startOf("day")
					.toDate();
				const calend = moment(pNode.end)
					.startOf("day")
					.add(1, "day")
					.toDate();
				if (
					!(
						calstart.getTime() <=
							this.props.app.ui.calStart.getTime() +
								this.props.app.ui.calDuration &&
						this.props.app.ui.calStart <= calend
					)
				)
					this.props.app.ui.setCalStart(calstart);
			}
		} else {
			global = true;
			newProject = true;
			projectData = this.getStandard(pid);
		}

		this.props.appCtx.setEditButtons(
			projectData.editable ? (
				<div className="topbutton" onClick={() => this.save()}>
					<img alt="Save" src={SaveImg} />
				</div>
			) : (
				true
			),
			<ConfirmButton
				question="questions.return"
				ok="yes"
				cancel="no"
				onClick={() => this.back()}
				trigger={
					<div className="topbutton">
						<img alt="Back" src={WahlImg} />
					</div>
				}
				checkSkip={() => this.checkForChanges()}
			/>
		);

		const suppliers = new Set();

		for (let o of orderData.values()) {
			if (!o.supplier) continue;
			suppliers.add(o.supplier);
		}

		for (let o of dispoData.values()) {
			if (!o.supplier) continue;
			suppliers.add(o.supplier);
		}

		this.setState({
			pid,
			newProject,
			global,
			suppliers, //TODO SUPPLIERS
			history: [],
			data: projectData,
			neverChanged: true,
			orders: orderData,
			agfAcceptedMaterials: Array.from(agfAcceptedMaterials)
				.map((x) => x.split("#####"))
				.sort((a, b) => a[0].localeCompare(b[0])),
			disposition: dispoData,
			requests: requestData,  
			marked: new Set(),
		});
		this.saveHistory();
	}

	addSupplier(id) {
		if (!id) return false;
		this.setState((x) => {
			let fx = new Set(x.suppliers);
			fx.add(id);
			return { suppliers: fx };
		});
	}

	resolveOrderDisposition(snapshot) {
		return snapshot;
	}

	getStandard(pid) {
		return {
			id: pid,
			name: "",
			username: localStorage.getItem("binfra_username"),
			bstn: "",
			client: "",
			suppliers: new Set(),
			moveable: true,
			fixed: false,
			editable: true,
			position: { lat: 0, lng: 0, zoom: 0 },
			comment: "",
			contacts: {},
			projectAttachments: {},
			costbase: "",
			updatedAt: new Date(),
			processes: {},
		};
	}

	getUnified() {
		let upperout = new Map();
		let main_out = new Map();
		upperout.set("main", {});
		
		for (let process of Object.values(this.state.data.processes).sort(
			(a, b) => {
				let astart = 8640000000000000; //DATE_MAX
				let bstart = 8640000000000000; //DATE_MAX
				Object.values(a.jobs).map((x) => (astart = Math.min(astart, x.start)));
				Object.values(b.jobs).map((x) => (bstart = Math.min(bstart, x.start)));

				return astart < bstart ? -1 : astart > bstart ? 1 : 0;
			}
		)) {
			if (process.deleted) continue;
			let processId = process.id;
			const keyCollection = [];
			let process_out = new Map();
			for (let job of Object.values(process.jobs).sort((a, b) =>
				a.start < b.start ? -1 : a.start > b.start ? 1 : 0
			)) {
				if (job.deleted) continue;
				let start = moment(job.start).startOf("day");
				let key = start.format("YYYY-MM-DD");
				let end = moment(job.start).endOf("day");

				if (!main_out.has(key)) {
					main_out.set(key, {
						start: start.toDate(),
						end: end.toDate(),
						processes: new Map(),
						bpo: "",
					});
				}
				main_out.get(key).processes.set(processId, true);

				if (process_out.has(key)) continue;
				let letter;
				if (job.bpo.length) {
					letter = "";
				} else {
					const hash = this.jobHash(job);

					const index = keyCollection.indexOf(hash);
					if (index < 0) {
						letter = this.intToLetter(keyCollection.length);
						keyCollection.push(hash);
					} else {
						letter = this.intToLetter(index);
					}
				}

				process_out.set(key, {
					start: start.toDate(),
					end: end.toDate(),
					bpo: job.bpo,
					group: letter,
				});
			}
			upperout.set(processId, {
				name: process.name,
				bstn: process.bstn,
				processId: processId,
				data: process_out,
				template: process.template,
			});
		}
		/* eslint-enable no-unused-vars */

		upperout.set("main", {
			name:
				this.state.data.bstn +
				(this.state.data.bstn.length ? ": " : "") +
				this.state.data.name,
			processId: "main",
			data: main_out,
		});

		return upperout;
	}

	intToLetter(nNum1) {
		let number = nNum1 + 1;
		var baseChar = "A".charCodeAt(0),
			letters = "";
		do {
			number -= 1;
			letters = String.fromCharCode(baseChar + (number % 26)) + letters;
			number = (number / 26) >> 0; // quick `floor`
		} while (number > 0);

		return letters;
	}

	jobHash(job) {
		const c = {
			start: moment(job.start).format("HH:mm"),
			end: moment(job.end).format("HH:mm"),
			pos:
				"lat" in job.position ? job.position.lat + "," + job.position.lng : "",
		};
		for (let id of job.disposition) {
			const dispo = this.state.disposition.has(id)
				? this.state.disposition.get(id)
				: { deleted: true };
			if (dispo.deleted) continue;
			const pkey = dispo.supplier + "-" + dispo.type;
			if (!(pkey in c)) c[pkey] = 0;
			c[pkey] += dispo.amount;
		}

		for (let id of job.orders) {
			const order = this.state.orders.has(id)
				? this.state.orders.get(id)
				: { deleted: true };
			if (order.deleted) continue;
			const pkey = order.supplier + "-" + order.material;
			if (!(pkey in c)) c[pkey] = 0;
			c[pkey] += order.amount;
		}
		let o = "";
		for (let key of Object.keys(c).sort((a, b) =>
			a < b ? -1 : a > b ? 1 : 0
		)) {
			o += key + ":" + c[key] + ";";
		}
		return md5(o);
	}

	addJob(date, process, e) {
		const id = uuid.v4();
		let companyName = this.props.app.basedata.company?.name ?? "";
		// let start = (companyName === 'willemen' ?  moment(date + " 07:30:00").valueOf() : moment(date + " 08:00:00").valueOf());
		// let end = moment(date + " 17:00:00").valueOf();		
		let start;
		if (companyName === 'willemen') {
			start = moment(date + " 07:30:00").valueOf();
		} else if (companyName === 'boskalisnetherlands') {
			start = moment(date + " 07:00:00").valueOf();
		} else {
			start = moment(date + " 08:00:00").valueOf();
		}
		let end;
		if (companyName === 'boskalisnetherlands') {
			end = moment(date + " 16:00:00").valueOf();
		} else {
			end = moment(date + " 17:00:00").valueOf();
		}
		
		this.setState((state) => {
			let processname = state.data.processes[process].name;
			let nightstr = this.props.t("night");
			const reg = new RegExp("[$ ]" + nightstr, "gi");

			if (reg.test("$" + processname + "$")) {
				start = moment(date + " 20:00:00").valueOf();
				end = moment(date + " 06:00:00")
					.add(1, "day")
					.valueOf();
			} else if (this.props.app.ui.modules.has("TIME_7_TO_16")) {
				start = moment(date + " 07:00:00").valueOf();
				end = moment(date + " 16:00:00").valueOf();
			}

			return {
				neverChanged: false,
				data: {
					...state.data,
					processes: {
						...state.data.processes,
						[process]: {
							...state.data.processes[process],
							jobs: {
								...state.data.processes[process].jobs,
								[id]: {
									id: id,
									start,
									end,
									name: "",
									disposition: [],
									requests: [],
									orders: [],
									updatedAt: new Date(),
									bpo: "",
									truckTonnage: null,
									comment: "",
									deleted: false,
									position: { lat: 0, lng: 0, zoom: 0 },
								},
							},
						},
					},
				},
			};
		});
		if (!this.state.deleteMode) this.handleDayClick(date, process, e, true);
		else this.saveHistory();
	}

	move(moveList) { 
		this.setState((state) => {
			const data = { ...state.data };
			for (let [p, j, diff] of moveList) {
				data.processes[p].jobs[j].start += diff;
				data.processes[p].jobs[j].end += diff;
				data.processes[p].jobs[j].updatedAt = new Date();
			}
			return { data };
		});
	} 

	setRollerdata(processId, jobId, rollerDataJSON) {
		this.setState((state) => {
			const data = { ...state.data };			 
			data.processes[processId].jobs[jobId].roller_data = rollerDataJSON; 
			return { data };
		});
	} 

	setCarbondata(processId, jobId, carbonDataJSON) {
		this.setState((state) => {
			const data = { ...state.data };			 
			data.processes[processId].jobs[jobId].carbon_data = carbonDataJSON; 
			return { data };
		});
	}

	getRollerData(processId, jobId){ 
		const data = this.state.data;	
		return data.processes[processId].jobs[jobId].roller_data;		 
	  }
	  

	copyMode() {  
		this.setState((oldState) => {
			if (oldState.copyOrigins.size) {
				return { copyOrigins: new Set() };
			}
			if (!oldState.marked.size) {
				this.props.app.ui.openMessage("edit.copyChooseWarning");
				return;
			} 
			return { copyOrigins: new Set(oldState.marked), marked: new Set() };
		});
	}

	copy(oldState, targetProcess, target) {
		const data = clonedeep(oldState.data);
		let disposition = clonedeep(oldState.disposition);
		let orders = clonedeep(oldState.orders);
		let requests = clonedeep(oldState.requests);

		let first = false;
		let originProcess = false;
		const copyMap = {};
		for (let originData of Array.from(oldState.copyOrigins.values()).sort()) {
			let [oP, origin] = originData.split("#");
			if (first === false) {
				originProcess = oP;
				first = moment(origin);
				copyMap[origin] = target;
			} else {
				copyMap[origin] = moment(target)
					.add(moment(origin).diff(first, "day"), "day")
					.format("YYYY-MM-DD");
			}
		}

		const blockedDays = new Set();
		const addedJobs = {};
		const updateJobs = {};
		const addedDispo = {};
		const addedRequests = {};
		const addedOrders = {};
		const deletedJobs = {};

		const oldJobsKeysList = {};

		for (let jobId in oldState.data.processes[originProcess].jobs) {
			const job = oldState.data.processes[originProcess].jobs[jobId];
			if (job.deleted) continue;
			const key = moment(job.start).format("YYYY-MM-DD");

			oldJobsKeysList[key] = jobId;
		}

		//iterate over all jobs in oldState.
		for (let jobId in oldState.data.processes[originProcess].jobs) {
			const job = oldState.data.processes[originProcess].jobs[jobId];
			if (job.deleted) continue;
			const key = moment(job.start).format("YYYY-MM-DD");

			let replaceJob = false;
			const target = copyMap[key];
			let targetJob = null;
			if (target in oldJobsKeysList) { // If target already exists
				replaceJob = true;
				targetJob = oldState.data.processes[originProcess].jobs[oldJobsKeysList[target]]
			}
			
			if (key in copyMap) { //if they are in the copyMap's keys:
				//copy job and add it into addedJobs etc., keyed by targetDate
				const target = copyMap[key];
		
				const dispoList = [];
				const orderList = [];
				const requestList = [];
				let id = uuid.v4();
				
				if(replaceJob === true){
					id = targetJob.id;
				}

				if(replaceJob){
					if (!(target in updateJobs)) {
						updateJobs[target] = {};
						addedDispo[target] = new Map();
						addedRequests[target] = new Map();
						addedOrders[target] = new Map();
					}
				}else{
					if (!(target in addedJobs)) {
						addedJobs[target] = {};
						addedDispo[target] = new Map();
						addedRequests[target] = new Map();
						addedOrders[target] = new Map();
					}
				}

				for (let orderId of job.orders) {
					if (!orders.has(orderId)) continue;
					const order = orders.get(orderId);
					if (order.deleted) continue;
					const xid = uuid.v4();
					addedOrders[target].set(xid, {
						id: xid,
						job: id,
						updatedAt: new Date(),
						deleted: false,
						material: order.material,
						supplier: order.supplier,
						amount: order.amount,
					});
					orderList.push(xid);
				}

				if (!this.props.app.ui.settings.disableRequestCopy) {
					for (let requestId of job.requests) {
						if (!requests.has(requestId)) continue;
						const request = requests.get(requestId);
						if (request.deleted) continue;
						const xid = uuid.v4();
						addedRequests[target].set(xid, {
							id: xid,
							job: id,
							updatedAt: new Date(),
							deleted: false,
							requirement: request.requirement,
							count: request.count,
							comment: request.comment,
						});
						requestList.push(xid);
					}
				}

				for (let dispoId of job.disposition) {
					if (!disposition.has(dispoId)) continue;
					const dispo = disposition.get(dispoId);
					if (dispo.deleted) continue;
					const xid = uuid.v4();
					addedDispo[target].set(xid, {
						id: xid,
						job: id,
						deleted: false,
						type: dispo.type,
						updatedAt: new Date(),
						supplier: dispo.supplier,
						amount: dispo.amount,
					});
					dispoList.push(xid);
				}

				const diff = moment(target).diff(moment(key), "day");
				if(replaceJob){

					updateJobs[target][id] = {
						id: id,
						start: moment(job.start)
							.add(diff, "day")
							.valueOf(),
						end: moment(job.end)
							.add(diff, "day")
							.valueOf(),
						name: "name" in job ? job.name : "",
						disposition: dispoList,
						orders: orderList,
						requests: requestList,
						updatedAt: new Date(),
						bpo: "",
						comment: job.comment,
						deleted: false,
						position: job.position,
					};
				}else{
					addedJobs[target][id] = {
						id: id,
						start: moment(job.start)
							.add(diff, "day")
							.valueOf(),
						end: moment(job.end)
							.add(diff, "day")
							.valueOf(),
						name: "name" in job ? job.name : "",
						disposition: dispoList,
						orders: orderList,
						requests: requestList,
						updatedAt: new Date(),
						bpo: "",
						comment: job.comment,
						deleted: false,
						position: job.position,
					};
				}
				
			}
		}

		for (let jobId in oldState.data.processes[targetProcess].jobs) {
			const job = oldState.data.processes[targetProcess].jobs[jobId];
			const key = moment(job.start).format("YYYY-MM-DD");
			//if they are in the copyMap's values:
			if (Object.values(copyMap).includes(key)) {
				// //add to deleted jobs keyed by date
				// if (!(key in deletedJobs)) deletedJobs[key] = new Set();
				// deletedJobs[key].add(jobId);
				
			}
			//if is bpo add date to blockedDays
			if (job.bpo.length) {
				blockedDays.add(key);
			}
		}

		// Perorm replace
		for (let target in updateJobs) {
			if (blockedDays.has(target)) continue;

			let jobId = oldJobsKeysList[target];

			let oldJob = data.processes[targetProcess].jobs[jobId];

			let oldDispos = oldJob.disposition;
			let oldOrders = oldJob.orders;
			let oldRequests = oldJob.requests;

			data.processes[targetProcess].jobs[jobId] = updateJobs[target][jobId];

			// Delete existing dispos, orders, requests

			for(let dispo in oldDispos){
				disposition.get(oldDispos[dispo]).deleted = true;
				disposition.get(oldDispos[dispo]).updatedAt = new Date();
			}

			for(let order in oldOrders){
				orders.get(oldOrders[order]).deleted = true;
				orders.get(oldOrders[order]).updatedAt = new Date();
			}

			if (!this.props.app.ui.settings.disableRequestCopy) {
				for(let request in oldRequests){
					requests.get(oldRequests[request]).deleted = true;
					requests.get(oldRequests[request]).updatedAt = new Date();
				}
			}
			
			/* eslint-disable no-loop-func */
			disposition = new Map(
				(function*() {
					yield* disposition;
					yield* addedDispo[target];
				})()
			);
			orders = new Map(
				(function*() {
					yield* orders;
					yield* addedOrders[target];
				})()
			);
			requests = new Map(
				(function*() {
					yield* requests;
					yield* addedRequests[target];
				})()
			);
			/* eslint-enable no-loop-function */
		}

		//perform addedJobs, addedOrders, addedDispo, deletedJobs if date not in blockedDays
		for (let target in addedJobs) {
			if (blockedDays.has(target)) continue;
			data.processes[targetProcess].jobs = {
				...data.processes[targetProcess].jobs,
				...addedJobs[target],
			};
			/* eslint-disable no-loop-func */
			disposition = new Map(
				(function*() {
					yield* disposition;
					yield* addedDispo[target];
				})()
			);
			orders = new Map(
				(function*() {
					yield* orders;
					yield* addedOrders[target];
				})()
			);
			requests = new Map(
				(function*() {
					yield* requests;
					yield* addedRequests[target];
				})()
			);
			/* eslint-enable no-loop-function */
		}

		for (let target in deletedJobs) {
			if (blockedDays.has(target)) continue;
			// for (let jobId of deletedJobs[target])
			// 	data.processes[targetProcess].jobs[jobId] = {
			// 		...data.processes[targetProcess].jobs[jobId],
			// 		deleted: true,
			// 		updatedAt: new Date(),
			// 	};
		}

		//Save Roller data
		const originJobDays = oldState.data.processes[originProcess].jobs;
		const targetJobDays = oldState.data.processes[targetProcess].jobs; 
		let originJobData;
		let targetJobData;
		for (const [originJobDate, targetJobDate] of Object.entries(copyMap)) {
			originJobData = Object.values(originJobDays).filter( (job) => job.bpo && moment(job.start).format("YYYY-MM-DD") === originJobDate );
			targetJobData = Object.values(targetJobDays).filter( (job) => job.bpo && moment(job.start).format("YYYY-MM-DD") === targetJobDate );
			

			if( data.processes[targetProcess].jobs[targetJobData[0]?.id]?.bpo && data.processes[originProcess].jobs[originJobData[0]?.id]?.bpo )
			data.processes[targetProcess].jobs[targetJobData[0].id].roller_data = data.processes[originProcess].jobs[originJobData[0].id].roller_data;
			
			break;
		}

		console.log('data', data, 'consolelog copy() data');

		return {
			data,
			disposition,
			orders,
			requests,
			marked: new Set([targetProcess + "#" + target]),
			global: false, 
			copyOrigins: new Set(),
		};
	}

	chooseInterval(process, dateInterval, onlyWorkdays) {

		console.log(dateInterval.start,dateInterval.end);

		const begin = moment(dateInterval.start);
		const end = moment(dateInterval.end);

		if (!begin.isValid() || !end.isValid() || !process) return;

		this.setState((state) => {
			const newJobs = {};
			const newMarked = new Set();
			const existingDays = new Set();
			if (!(process in state.data.processes)) {
				return;
			}

			for (let job of Object.values(state.data.processes[process].jobs)) {
				existingDays.add(moment(job.start).format("YYYY-MM-DD"));
			}

			for (let date = moment(begin); date <= end; date.add(1, "day")) {
				const key = date.format("YYYY-MM-DD");

				//skip it if onlyWorkdays and no workday
				if (
					onlyWorkdays &&
					!this.props.app.basedata.checkWorkingDay(date).state
				)
					continue;

				newMarked.add(process + "#" + key);

				//skip it if there already exists a job on this day
				if (existingDays.has(key)) continue;

				const id = uuid.v4();

				let start = moment(key + " 08:00:00").valueOf();
				let end = moment(key + " 17:00:00").valueOf();

				let processname = state.data.processes[process];
				let nightstr = this.props.t("night");
				const reg = new RegExp("[$ ]" + nightstr, "gi");

				if (reg.test("$" + processname + "$")) {
					start = moment(key + " 20:00:00").valueOf();
					end = moment(key + " 06:00:00")
						.add(1, "day")
						.valueOf();
				} else if (this.props.app.ui.modules.has("TIME_7_TO_16")) {
					start = moment(key + " 07:00:00").valueOf();
					end = moment(key + " 16:00:00").valueOf();
				}
				newJobs[id] = {
					id: id,
					start,
					end,
					name: "",
					disposition: [],
					requests: [],
					orders: [],
					updatedAt: new Date(),
					bpo: "",
					comment: "",
					deleted: false,
					position: { lat: 0, lng: 0, zoom: 0 },
				};
			}

			return {
				neverChanged: false,
				marked: newMarked,
				data: {
					...state.data,
					processes: {
						...state.data.processes,
						[process]: {
							...state.data.processes[process],
							jobs: {
								...state.data.processes[process].jobs,
								...newJobs,
							},
						},
					},
				},
			};
		});
	}

	handleDayClick(date, process, e, historicalChange = false) {		 
		this.setState((oldState) => {
			if (oldState.copyOrigins.size) {
				historicalChange = true;
				return this.copy(oldState, process, date);
			}

			if (
				oldState.marked.has(process + "#" + date) &&
				(e.ctrlKey || e.metaKey || oldState.multiMode)
			) {
				let newSet = new Set(oldState.marked);
				newSet.delete(process + "#" + date);
				return { marked: newSet, global: false };
			}
			if (e.ctrlKey || e.metaKey || oldState.multiMode) {
				if (
					oldState.marked.size > 0 &&
					oldState.marked
						.values()
						.next()
						.value.split("#")[0] !== process
				) {
					return { marked: new Set([process + "#" + date]), global: false };
				}
				let newSet = new Set(oldState.marked);
				newSet.add(process + "#" + date);
				return { marked: newSet, global: false };
			} else {
				return { marked: new Set([process + "#" + date]), global: false };
			}
		});
		if (historicalChange) this.saveHistory();
	}

	retrieveJobData(c) {
		let [process, date] = c.split("#");
		const out = [];
		const jobs = this.state.data.processes[process].jobs;
		Object.keys(jobs).map(function(key) {
			if (
				!jobs[key].deleted &&
				date === moment(jobs[key].start).format("YYYY-MM-DD")
			) {
				out.push({
					id: process + "#" + key,
					data: {
						...omit(jobs[key], ["id", "deleted"]),
						start: moment(jobs[key].start).format("HH:mm"),
						end: moment(jobs[key].end).format("HH:mm"),
						paving_start: moment(jobs[key].paving_start).format("HH:mm"),
					},
				});
			}
			return true;
		});
		return out;
	}

	openMixtureLetter(){
		const asphaltData = {
			pid: this.state.pid,
			assertUser: localStorage.getItem("binfra_username"),
		};

		let compressed = "";
		try {
			compressed = LZString.compressToEncodedURIComponent(
				JSON.stringify(asphaltData)
			);
		} catch (e) {
			compressed = encodeURIComponent(
				Buffer.from(JSON.stringify(asphaltData)).toString("base64")
			);
		}
		window.open(""+process.env.REACT_APP_BPO_URL+"/?nmb2=infralink&d=" + compressed,'_blank');
		/*
			Object.assign(document.createElement('a'), {
    		target: '_blank',
    		href: href,
  		}).click();
		*/
	}

	openBAP() {
		//force save first!
		const deleteThem = [];
		const processes = [];
		for (let process of Object.values(this.state.data.processes)) {
			for (let job of Object.values(process.jobs)) {
				if (!!job.bpo || job.deleted) {
					deleteThem.push(job.id);
					continue;
				}
				processes.push({
					id: job.id,
					name: process.name,
					date: moment(job.start)
						.startOf("day")
						.format("YYYY-MM-DD"),
				});
			}
		}
		const asphaltData = {
			pid: this.state.pid,
			processes,
			deleteThem,
			bstn: this.state.data.bstn,
			kostenstelle: this.state.data.costbase,
			contacts: Object.values(this.state.data.contacts)
				.map((x) => {
					const person = this.props.app.basedata.persons.has(x.person)
						? this.props.app.basedata.persons.get(x.person)
						: false;
					if (!person || person.deleted || x.deleted) return false;
					return {
						username: person.username,
						name: person.name,
						function: x.function,
						type: person.type
					};
				})
				.filter((x) => x),
			name: this.state.data.name,
			position: this.state.data.position,
			assertUser: localStorage.getItem("binfra_username"),
			username: this.state.data.username,
		};

		let compressed = "";

		try {
			compressed = LZString.compressToEncodedURIComponent(
				JSON.stringify(asphaltData)
			);
		} catch (e) {
			compressed = encodeURIComponent(
				Buffer.from(JSON.stringify(asphaltData)).toString("base64")
			);
		}

		this.setState({
			'hidden_form_value': compressed
		});
		setTimeout(() => this.myRef.current.click(), 1000);

		// window.location =
			// ""+process.env.REACT_APP_BPO_URL+"/?newbauprojekt&infra=" + compressed;
	}

	removeJob(date, process) { 
		this.setState((state) => { 
			const jobs = JSON.parse(
				JSON.stringify(state.data.processes[process].jobs)
			); 
			Object.keys(jobs).map(function(key) {
				if (date === moment(jobs[key].start).format("YYYY-MM-DD")) {
					jobs[key].deleted = true;
					jobs[key].updatedAt = new Date();
				}
				return true;
			});
			return {
				neverChanged: false,
				data: {
					...state.data,
					processes: {
						...state.data.processes,
						[process]: {
							...state.data.processes[process],
							jobs: jobs,
						},
					},
				},
			};
		});
		this.saveHistory();		
	}

	removeMultipleJobs(markedJobs) { 		 
		let process;
		const jobsArr = [];
		markedJobs.forEach((e) => {			
			const [processId, jobDate] = e.split("#"); 
			process = processId;
			jobsArr.push(jobDate);
		});			
		this.setState((state) => { 
			const jobs = JSON.parse(
				JSON.stringify(state.data.processes[process].jobs)
			); 
			Object.keys(jobs).map(function(key) { 
				if (jobsArr.includes(moment(jobs[key].start).format("YYYY-MM-DD"))  ) { 
					jobs[key].deleted = true;
					jobs[key].updatedAt = new Date();
				}
				return true;
			});
			return {
				neverChanged: false,
				data: {
					...state.data,
					processes: {
						...state.data.processes,
						[process]: {
							...state.data.processes[process],
							jobs: jobs,
						},
					},
				},
			};
		});
		setTimeout(() => {
			this.saveHistory();		
		}, 100);				
	}	

	setDeleteMode(deleteMode) {
		//this.setState({ deleteMode, marked: new Set() });
		const markedJobs = this.state.marked;
		if(markedJobs.size){
			this.removeMultipleJobs(markedJobs);	
		}
	}

	getLiteProcessData(processId) {
		const process = this.state.data.processes[processId];
		const jobs = [];
		for (let jobId in process.jobs) {
			const job = process.jobs[jobId];
			if (job.deleted) continue;

			const ama = {};

			for (let orderId of job.orders) {
				const order = this.state.orders.has(orderId)
					? this.state.orders.get(orderId)
					: { deleted: true };
				if (order.deleted) continue;
				const supplier = this.props.app.basedata.suppliers.has(order.supplier)
					? this.props.app.basedata.suppliers.get(order.supplier)
					: { deleted: true };
				const material = this.props.app.basedata.materials.has(order.material)
					? this.props.app.basedata.materials.get(order.material)
					: { deleted: true };
				if (supplier.deleted || material.deleted || order.amount < 1) continue;
				if (!(supplier.name in ama))
					ama[supplier.name] = {
						material: {},
						trucks: 0,
					};
				if (!(material.fullname in ama[supplier.name].material))
					ama[supplier.name].material[material.fullname] = 0;
				ama[supplier.name].material[material.fullname] += order.amount;
			}

			for (let dispoId of job.disposition) {
				const dispo = this.state.disposition.has(dispoId)
					? this.state.disposition.get(dispoId)
					: { deleted: true };
				if (dispo.deleted) continue;
				const supplier = this.props.app.basedata.suppliers.has(dispo.supplier)
					? this.props.app.basedata.suppliers.get(dispo.supplier)
					: { deleted: true };
				if (supplier.deleted || dispo.amount < 1) continue;
				if (!(supplier.name in ama)) continue;
				ama[supplier.name].trucks += dispo.amount;
			}

			jobs.push({
				start: moment(job.start).format("YYYY-MM-DD HH:mm:ss"),
				ama,
				position:
					job.position && job.position.lat !== 0
						? job.position
						: this.state.data.position,
			});
		}
		return {
			id: processId,
			name: process.name,
			deleted: process.deleted,
			jobs,
		};
	}

	changeProperty(sub, changes) {
		if (sub === "main") {
			this.setState((state) => ({
				neverChanged: false,
				data: {
					...state.data,
					updatedAt: new Date(),
					...changes,
				},
			}));
		} else {
			this.setState((state) => ({
				neverChanged: false,
				data: {
					...state.data,
					processes: {
						...state.data.processes,
						[sub]: {
							...state.data.processes[sub],
							updatedAt: new Date(),
							...changes,
						},
					},
				},
			}));
		}
	}

	setFixed(fixed){
		this.changeProperty("main",{fixed})
	}


	changeJobProperty(changeFunction) {
		this.setState((state) => ({
			neverChanged: false,
			...changeFunction(state),
		}));
	}

	addProcess(template) {
		const id = uuid.v4();
		this.setState((state) => ({
			neverChanged: false,
			data: {
				...state.data,
				processes: {
					...state.data.processes,
					[id]: {
						id: id,
						name: template.name,
						template: template.id,
						bstn: "",
						updatedAt: new Date(),
						jobs: {},
					},
				},
			},
		}));
		this.saveHistory();
	}

	save() { 
		this.props.app.projects.saveProjectEdit(
			this.state.data,
			this.state.orders,
			this.state.disposition,
			this.state.requests
		);
		this.props.app.ui.save();
		this.getProjectData(this.state.pid, false);
		this.props.history.push("/project/" + this.state.pid);
	}

	back() {
		this.props.history.push("/");
	}

	getDays() {
		let out = new Map();
		
		for (let process of Object.values(
			this.state.data.processes
		)) {
			if (process.deleted) continue;
			for (let job of Object.values(process.jobs)) {
				if (job.deleted) continue;
				let start = moment(job.start).startOf("day");
				let key = start.format("YYYY-MM-DD");

				if (out.has(key)) continue;

				out.set(key, 0);
			}
		}

		return out;
	}

	getUnifiedByDayWithProcesses() {
		let upperout = new Map();
		let main_out = new Map();
		upperout.set("main", {});
		
		for (let process of Object.values(this.state.data.processes).sort((a, b) =>
			a.start < b.start ? -1 : a.start > b.start ? 1 : 0
		)) {
			if (process.deleted) continue;
			let processId = process.id;
			let process_out = new Map();
			for (let job of Object.values(process.jobs)) {
				if (job.deleted) continue;
				let start = moment(job.start).startOf("day");
				let key = start.format("YYYY-MM-DD");
				let end = moment(job.start).endOf("day");

				if (!main_out.has(key)) {
					main_out.set(key, {
						start: start.toDate(),
						end: end.toDate(),
						processes: new Map(),
						isNight: job.isNight,
					});
				}
				main_out.get(key).processes.set(processId, true);
				main_out.get(key).isNight = main_out.get(key).isNight || job.isNight;

				if (process_out.has(key)) {
					process_out.get(key).isNight =
						process_out.get(key).isNight || job.isNight;
					continue;
				}

				process_out.set(key, {
					isNight: job.isNight,
					start: start.toDate(),
					end: end.toDate(),
					bpo: job?.bpo					
				});
			}
			upperout.set(processId, {
				name: process.name,
				processId: processId,
				data: process_out,
			});
		}
		/* eslint-enable no-unused-vars */

		upperout.set("main", {
			name:
				this.state.data.bstn +
				(this.state.data.bstn.length ? ": " : "") +
				this.state.data.name,
			processId: "main",
			data: main_out,
		});
		return upperout;
	}
	getUnifiedByDay() {
		let upperout = new Map();
		let out = new Map();
		
		for (let process of Object.values(
			this.state.data.processes
		)) {
			if (process.deleted) continue;
			for (let job of Object.values(process.jobs)) {
				if (job.deleted) continue;
				let start = moment(job.start).startOf("day");
				let key = start.format("YYYY-MM-DD");

				if (out.has(key)) {
					out.get(key).isNight = out.get(key).isNight || job.isNight;
					continue;
				}

				let end = moment(job.start).endOf("day");

				out.set(key, {
					start: start.toDate(),
					end: end.toDate(),
					isNight: job.isNight,
				});
			}
		}
		/* eslint-enable no-unused-vars */

		upperout.set("main", {
			name:
				this.state.data.bstn +
				(this.state.data.bstn.length ? ": " : "") +
				this.state.data.name,
			processId: "main",
			data: out,
		});
		return upperout;
	}

	render() {
		
		return (
			<div id="calEditPage" className="calPage">
				<CalendarButtons edit={this} />
				<ContainerDimensions>
					<Calendar edit={this} />
				</ContainerDimensions>
				<GlobalProjectEdit edit={this} />
				{ /* !this.state.deleteMode && !this.state.copyOrigins.size ? ( */
				!this.state.deleteMode ? (
					<ProjectEditView
						global={this.state.global}
						edit={this}
						marked={this.state.marked} 
						forceRemerge={this.state.forceRemerge}
					/>
				) : null }
 

			</div>
		);
	}
}

export default inject("app")(
	withRouter(withTranslation()(observer(ProjectEditPage)))
);
